import React from "react";
import HomeContent from "../components/Home/HomeContent";


const Home = () => {
  return (
    <>
      <div className="page">
        <HomeContent />
      </div>
    </>
  );
};

export default Home;
