import React from "react";
import ProjectContent from "../components/Project/ProjectContent";

const Project = () => {
  return (
    <>
      <div className="page">
        <ProjectContent/>
      </div>
    </>
  );
};

export default Project;
