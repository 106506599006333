export const menuLinks = [
  {
    to: "/",
    name: "Inicio",
  },
  {
    to: "/sobre",
    name: "Sobre",
  },
  {
    to: "/projetos",
    name: "Projetos",
  },
  {
    to: "/contato",
    name: "Contato",
  },
];
