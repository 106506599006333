import React from "react";
import AboutContent from "../components/About/AboutContent";

const About = () => {
  return (
    <>
      <div className="page">
        <AboutContent/>
      </div>
    </>
  );
};

export default About;
